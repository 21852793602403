import { CHAIN_TO_ADDRESSES_MAP, ChainId, Token } from '@uniswap/sdk-core'
import { FACTORY_ADDRESS } from '@uniswap/v3-sdk'

import { NETWORKS_WITH_SAME_UNISWAP_ADDRESSES } from './chains'

// export const BNB_TICK_LENS_ADDRESS = CHAIN_TO_ADDRESSES_MAP[ChainId.BNB].tickLensAddress;
// export const BNB_NONFUNGIBLE_POSITION_MANAGER_ADDRESS = CHAIN_TO_ADDRESSES_MAP[ChainId.BNB].nonfungiblePositionManagerAddress;
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
// export const BNB_SWAP_ROUTER_02_ADDRESS = CHAIN_TO_ADDRESSES_MAP[ChainId.BNB].swapRouter02Address!;
// export const BNB_V3_MIGRATOR_ADDRESS = CHAIN_TO_ADDRESSES_MAP[ChainId.BNB].v3MigratorAddress;

export const POOL_INIT_CODE_HASH: AddressMap = {
  [ChainId.OXSWAP_OX]: '0x8590794fefd5aa12346ffddfc03ff7c42393248a0a9025ce7dad6399a7bcac0f',
  [ChainId.OXSWAP_BOX]: '0x8590794fefd5aa12346ffddfc03ff7c42393248a0a9025ce7dad6399a7bcac0f'
}

export const V3_CORE_FACTORY_ADDRESSES: AddressMap = {
  ...constructSameAddressMap(FACTORY_ADDRESS),
  [ChainId.OXSWAP_OX]: CHAIN_TO_ADDRESSES_MAP[ChainId.OXSWAP_OX].v3CoreFactoryAddress,
  [ChainId.OXSWAP_BOX]: CHAIN_TO_ADDRESSES_MAP[ChainId.OXSWAP_BOX].v3CoreFactoryAddress,
  // [ChainId.OPTIMISM_GOERLI]: CHAIN_TO_ADDRESSES_MAP[ChainId.OPTIMISM_GOERLI].v3CoreFactoryAddress,
  // [ChainId.SEPOLIA]: CHAIN_TO_ADDRESSES_MAP[ChainId.SEPOLIA].v3CoreFactoryAddress,
  // [ChainId.ARBITRUM_GOERLI]: CHAIN_TO_ADDRESSES_MAP[ChainId.ARBITRUM_GOERLI].v3CoreFactoryAddress,
  // [ChainId.BNB]: CHAIN_TO_ADDRESSES_MAP[ChainId.BNB].v3CoreFactoryAddress,
  // [ChainId.AVALANCHE]: CHAIN_TO_ADDRESSES_MAP[ChainId.AVALANCHE].v3CoreFactoryAddress,
  // [ChainId.BASE_GOERLI]: CHAIN_TO_ADDRESSES_MAP[ChainId.BASE_GOERLI].v3CoreFactoryAddress,
  // [ChainId.BASE]: CHAIN_TO_ADDRESSES_MAP[ChainId.BASE].v3CoreFactoryAddress,
  // // TODO: Gnosis + Moonbeam contracts to be deployed
}

export const QUOTER_V2_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0x61fFE014bA17989E743c5F6cB21bF9697530B21e'),
  [ChainId.OXSWAP_OX]: CHAIN_TO_ADDRESSES_MAP[ChainId.OXSWAP_OX].quoterAddress,
  [ChainId.OXSWAP_BOX]: CHAIN_TO_ADDRESSES_MAP[ChainId.OXSWAP_BOX].quoterAddress,
  // [ChainId.OPTIMISM_GOERLI]: CHAIN_TO_ADDRESSES_MAP[ChainId.OPTIMISM_GOERLI].quoterAddress,
  // [ChainId.SEPOLIA]: CHAIN_TO_ADDRESSES_MAP[ChainId.SEPOLIA].quoterAddress,
  // [ChainId.ARBITRUM_GOERLI]: CHAIN_TO_ADDRESSES_MAP[ChainId.ARBITRUM_GOERLI].quoterAddress,
  // [ChainId.BNB]: CHAIN_TO_ADDRESSES_MAP[ChainId.BNB].quoterAddress,
  // [ChainId.AVALANCHE]: CHAIN_TO_ADDRESSES_MAP[ChainId.AVALANCHE].quoterAddress,
  // [ChainId.BASE_GOERLI]: CHAIN_TO_ADDRESSES_MAP[ChainId.BASE_GOERLI].quoterAddress,
  // [ChainId.BASE]: CHAIN_TO_ADDRESSES_MAP[ChainId.BASE].quoterAddress,
  // // TODO: Gnosis + Moonbeam contracts to be deployed
}

export const MIXED_ROUTE_QUOTER_V1_ADDRESSES: AddressMap = {
  [ChainId.OXSWAP_OX]: CHAIN_TO_ADDRESSES_MAP[ChainId.OXSWAP_OX].v1MixedRouteQuoterAddress,
  // [ChainId.GOERLI]: CHAIN_TO_ADDRESSES_MAP[ChainId.GOERLI].v1MixedRouteQuoterAddress,
}

export const UNISWAP_MULTICALL_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0x1F98415757620B543A52E61c46B32eB19261F984'),
  [ChainId.OXSWAP_OX]: CHAIN_TO_ADDRESSES_MAP[ChainId.OXSWAP_OX].multicallAddress,
  [ChainId.OXSWAP_BOX]: CHAIN_TO_ADDRESSES_MAP[ChainId.OXSWAP_BOX].multicallAddress,
  // [ChainId.OPTIMISM_GOERLI]: CHAIN_TO_ADDRESSES_MAP[ChainId.OPTIMISM_GOERLI].multicallAddress,
  // [ChainId.SEPOLIA]: CHAIN_TO_ADDRESSES_MAP[ChainId.SEPOLIA].multicallAddress,
  // [ChainId.ARBITRUM_GOERLI]: CHAIN_TO_ADDRESSES_MAP[ChainId.ARBITRUM_GOERLI].multicallAddress,
  // [ChainId.BNB]: CHAIN_TO_ADDRESSES_MAP[ChainId.BNB].multicallAddress,
  // [ChainId.AVALANCHE]: CHAIN_TO_ADDRESSES_MAP[ChainId.AVALANCHE].multicallAddress,
  // [ChainId.BASE_GOERLI]: CHAIN_TO_ADDRESSES_MAP[ChainId.BASE_GOERLI].multicallAddress,
  // [ChainId.BASE]: CHAIN_TO_ADDRESSES_MAP[ChainId.BASE].multicallAddress,
  // TODO: Gnosis + Moonbeam contracts to be deployed
}

export const SWAP_ROUTER_02_ADDRESSES = (chainId: number): string => {
  // if (chainId == ChainId.BNB) {
  //   return BNB_SWAP_ROUTER_02_ADDRESS
  // } else
  if (chainId == ChainId.OXSWAP_BOX) {
    return CHAIN_TO_ADDRESSES_MAP[ChainId.OXSWAP_BOX].swapRouter02Address!
  } else {
    return CHAIN_TO_ADDRESSES_MAP[ChainId.OXSWAP_OX].swapRouter02Address!
  }
}

export const OVM_GASPRICE_ADDRESS = '0x420000000000000000000000000000000000000F'
export const ARB_GASINFO_ADDRESS = '0x000000000000000000000000000000000000006C'
// export const TICK_LENS_ADDRESS = CHAIN_TO_ADDRESSES_MAP[ChainId.ARBITRUM_ONE].tickLensAddress
export const NONFUNGIBLE_POSITION_MANAGER_ADDRESS =
  CHAIN_TO_ADDRESSES_MAP[ChainId.OXSWAP_OX].nonfungiblePositionManagerAddress
export const V3_MIGRATOR_ADDRESS = CHAIN_TO_ADDRESSES_MAP[ChainId.OXSWAP_OX].v3MigratorAddress
// export const MULTICALL2_ADDRESS = '0x22EdB96DeE65847502e0908604FA5DfA7284A68b'

export type AddressMap = { [chainId: number]: string | undefined }

export function constructSameAddressMap<T extends string>(
  address: T,
  additionalNetworks: ChainId[] = []
): { [chainId: number]: T } {
  return NETWORKS_WITH_SAME_UNISWAP_ADDRESSES.concat(additionalNetworks).reduce<{
    [chainId: number]: T
  }>((memo, chainId) => {
    memo[chainId] = address
    return memo
  }, {})
}

export const WETH9: {
  [key: number]: Token
} = {
  [ChainId.OXSWAP_OX]: new Token(ChainId.OXSWAP_OX, '0xe0009848520C305C92eB13EAdd5676095f82Ab5f', 18, 'WOX', 'WOX'),
  [ChainId.OXSWAP_BOX]: new Token(
    ChainId.OXSWAP_BOX,
    '0x10EFb91d6953279A8f26E1BBF4065a19D0B2a52D',
    18,
    'WBOX',
    'WBOX'
  ),
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    18,
    'WETH',
    'Wrapped Ether'
  ),
}
