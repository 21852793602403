export const UNI_LIST = 'https://cloudflare-ipfs.com/ipns/tokens.uniswap.org'
export const UNI_EXTENDED_LIST = 'https://cloudflare-ipfs.com/ipns/extendedtokens.uniswap.org'

export const OXSWAP_OX_LIST = 'https://files.ox.fun/assets/oxchain/tokenlist.json'
export const OXSWAP_BOX_LIST = 'https://files.ox.fun/assets/oxchain/tokenlist.json'

export const UNSUPPORTED_LIST_URLS: string[] = [
  // BA_LIST, UNI_UNSUPPORTED_LIST
]

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [OXSWAP_OX_LIST, OXSWAP_BOX_LIST /*, UNI_LIST*/]
export const DEFAULT_INACTIVE_LIST_URLS: string[] = [...UNSUPPORTED_LIST_URLS]

export const DEFAULT_LIST_OF_LISTS: string[] = [...DEFAULT_ACTIVE_LIST_URLS, ...DEFAULT_INACTIVE_LIST_URLS]