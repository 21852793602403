import { Percent, ChainId } from '@uniswap/sdk-core'
import JSBI from 'jsbi'

export const FACTORY_ADDRESS: { [key: number]: string } = {
  [ChainId.OXSWAP_BOX]: '0xd51968B0CC6cd12EB0d72548eC99b1ecBbA76BBc',
  [ChainId.OXSWAP_OX]: '0x7c2CE87e6E9e56E67Eea14Fd18b8de6D1Ff4766e',
}

export const INIT_CODE_HASH: { [key: number]: string } = {
  [ChainId.OXSWAP_BOX]: '0x067daae79a5e0ceafba25c285b73adae8727eef79d1ef0ff06fc7dded178f92a',
  [ChainId.OXSWAP_OX]: '0xdd5afc11cd03a833b3359c987b43472b7cf52d06d9f5d029f6d5a9617f60ecef',
}

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const FIVE = JSBI.BigInt(5)
export const _997 = JSBI.BigInt(997)
export const _1000 = JSBI.BigInt(1000)
export const BASIS_POINTS = JSBI.BigInt(10000)

export const ZERO_PERCENT = new Percent(ZERO)
export const ONE_HUNDRED_PERCENT = new Percent(ONE)
