import { ApolloClient, ApolloLink, concat, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import { ChainId } from '@uniswap/sdk-core'

import store from '../../state/index'

const CHAIN_SUBGRAPH_URL: Record<number, string> = {
  [ChainId.OXSWAP_OX]: 'https://api.goldsky.com/api/public/project_clx4h8h7drk4m01uq12mc12kd/subgraphs/ox-v3/1.0.0/gn',
  [ChainId.OXSWAP_BOX]: 'https://api.goldsky.com/api/public/project_clw8kffr25vsj01tv06tj55h0/subgraphs/v3-subgraph/1.0.0/gn',
}

const CHAIN_BLOCK_SUBGRAPH_URL: Record<number, string> = {
  [ChainId.OXSWAP_OX]: 'https://api.goldsky.com/api/public/project_clx4h8h7drk4m01uq12mc12kd/subgraphs/ox-blocks/1.0.0/gn',
  [ChainId.OXSWAP_BOX]: 'https://api.goldsky.com/api/public/project_clw8kffr25vsj01tv06tj55h0/subgraphs/blocks-subgraph/1.0.0/gn',
}

const httpLink = new HttpLink({ uri: CHAIN_SUBGRAPH_URL[ChainId.OXSWAP_OX] })

// This middleware will allow us to dynamically update the uri for the requests based off chainId
// For more information: https://www.apollographql.com/docs/react/networking/advanced-http-networking/
const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const chainId = store.getState().application.chainId

  operation.setContext(() => ({
    uri: chainId && CHAIN_SUBGRAPH_URL[chainId] ? CHAIN_SUBGRAPH_URL[chainId] : CHAIN_SUBGRAPH_URL[ChainId.OXSWAP_OX],
  }))

  return forward(operation)
})

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),
})

export const chainToApolloClient: Record<number, ApolloClient<NormalizedCacheObject>> = {
  [ChainId.OXSWAP_OX]: new ApolloClient({
    cache: new InMemoryCache(),
    uri: CHAIN_SUBGRAPH_URL[ChainId.OXSWAP_OX],
  }),
  [ChainId.OXSWAP_BOX]: new ApolloClient({
    cache: new InMemoryCache(),
    uri: CHAIN_SUBGRAPH_URL[ChainId.OXSWAP_BOX],
  }),
}

export const chainToApolloBlockClient: Record<number, ApolloClient<NormalizedCacheObject>> = {
  [ChainId.OXSWAP_OX]: new ApolloClient({
    uri: CHAIN_BLOCK_SUBGRAPH_URL[ChainId.OXSWAP_OX],
    cache: new InMemoryCache(),
  }),
  [ChainId.OXSWAP_BOX]: new ApolloClient({
    uri: CHAIN_BLOCK_SUBGRAPH_URL[ChainId.OXSWAP_BOX],
    cache: new InMemoryCache(),
  }),
}
